// success
$edp1:    #A4090A !default;
// primary
$edp2:    #044389 !default;
// info
$edp3:    #93B7BE !default;
// error
$edp4:    #D45113 !default;
// warning
$edp5:    #F9A03F !default;
// secondary 
$edp6:    #adb5bd !default;

$colors: (
    "primary":    $edp2,
    "success":    $edp1,
    "info":       $edp3,
    "warning":    $edp5,
    "danger":     $edp1,
    "edp1":       $edp1,
    "edp2":       $edp2,
    "edp3":       $edp3,
    "edp4":       $edp4,
    "edp5":       $edp5,
    "edp6":       $edp6,
);


$theme-colors: (
    "primary":    $edp2,
    "secondary":  $edp6,
    "success":    $edp1,
    "info":       $edp3,
    "warning":    $edp5,
    "danger":     $edp1,
    "edp1":	  $edp1,
    "edp2":	  $edp2,
    "edp3":	  $edp3,
    "edp4":	  $edp4,
    "edp5":	  $edp5,
);


